import { i18n } from "@/main";
const t = i18n.global.t;
export default class DevicesTableData {
    static getColumns() {
        return [
            {
                name: "imei",
                align: "center",
                label: t("device.imei"),
                field: "imei",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "hardwareId",
                align: "center",
                label: t("device.hardwareId"),
                field: "hardwareId",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "macAddresss",
                align: "center",
                label: t("device.macAddress"),
                field: "macAddress",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "deviceName",
                align: "center",
                label: t("device.deviceName"),
                field: "deviceName",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "liftType",
                align: "center",
                label: t("device.liftType"),
                field: "liftType",
                format: (val) => `${val?.name ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "activeLocationName",
                align: "center",
                label: t("device.activeLocationName"),
                field: "activeLocationName",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "activeOrganizationName",
                align: "center",
                label: t("device.activeOrganizationName"),
                field: "activeOrganizationName",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "firmwareVersion",
                align: "center",
                label: t("device.firmwareVersion"),
                field: "firmwareVersion",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "firmwareCommit",
                align: "center",
                label: t("device.firmwareCommit"),
                field: "firmwareCommit",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 11,4%",
                headerStyle: "width: 11,4%",
            },
            {
                name: "isActive",
                align: "center",
                label: t("shared.isActive"),
                field: "isActive",
                format: (val) => `${val ? t("shared.active") : t("shared.inactive")}`,
                sortable: false,
                isBoolean: true,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "action-details",
                align: "left",
                label: "",
                field: "action-details",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "action-logs",
                align: "left",
                label: "",
                field: "action-logs",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
        ];
    }
}
